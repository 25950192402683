/* App */
export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION';
export const SET_SITE_HOSTNAME = 'SET_SITE_HOSTNAME';
export const SET_LOCATION_STATE = 'SET_LOCATION_STATE';
export const SET_CARDS_HOME = 'SET_CARDS_HOME';
export const CLEAR_SERVICE_CARD = 'CLEAR_SERVICE_CARD';
export const ASYNC_SET_GENERIC_SEARCH = 'ASYNC_SET_GENERIC_SEARCH';
export const SET_GENERAL_ERROR = 'SET_GENERAL_ERROR';
export const SET_GENERIC_SEARCH = 'SET_GENERIC_SEARCH';

/* Units */
export const SET_SEARCH_UNIT = 'SET_SEARCH_UNIT';
export const ADD_SERVICE_FILTER = 'ADD_SERVICE_FILTER';
export const ADD_FACILITIE_FILTER = 'ADD_FACILITIE_FILTER';
export const REMOVE_SERVICE_FILTER = 'REMOVE_SERVICE_FILTER';
export const REMOVE_FACILITIE_FILTER = 'REMOVE_FACILITIE_FILTER';
export const SET_DEFAULT_FILTERS = 'SET_DEFAULT_FILTERS';
export const SET_LIST_UNIT = 'SET_LIST_UNIT';
export const FILTER_LIST_UNIT = 'FILTER_LIST_UNIT';
export const LIST_UNIT_OPEN = 'LIST_UNIT_OPEN';
export const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT';
export const ASYNC_SET_LIST_UNIT = 'ASYNC_SET_LIST_UNIT';

/* SERVICE */
export const SEARCH_UNIT_SERVICE = 'SEARCH_UNIT_SERVICE';
export const SET_SERVICE_PAGE = 'SET_SERVICE_PAGE';
export const SET_UNIT_SERVICE = 'SET_UNIT_SERVICE';
export const SET_SERVICE_LIST = 'SET_SERVICE_LIST';
export const SET_SERVICE_DETAILS = 'SET_SERVICE_DETAILS';
export const CLEAR_SERVICE_DETAILS = 'CLEAR_SERVICE_DETAILS';
export const CLEAR_SERVICE_LIST = 'CLEAR_SERVICE_LIST';

export const ASYNC_SET_SERVICE_LIST = 'ASYNC_SET_SERVICE_LIST';
export const ASYNC_SET_SERVICE_DETAILS = 'ASYNC_SET_SERVICE_DETAILS';
export const ASYNC_SET_UNIT_SERVICE = 'ASYNC_SET_UNIT_SERVICE';
export const ASYNC_SEARCH_UNIT_SERVICE = 'ASYNC_SEARCH_UNIT_SERVICE';

/* TEMPLATE */
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
export const SET_STRUCTURE = 'SET_STRUCTURE';
export const SET_BANNER = 'SET_BANNER';
export const SET_SCHEDULE_CARDS = 'SET_SCHEDULE_CARDS';
export const SET_QUICK_ACCESS = 'SET_QUICK_ACCESS';
export const SET_SERVICE_CARDS = 'SET_SERVICE_CARDS';
export const SET_FOOTER = 'SET_FOOTER';
export const SET_UNIT_DETAILS = 'SET_UNIT_DETAILS';
export const SET_INSTITUTIONAL = 'SET_INSTITUTIONAL';
export const SET_VACCINE_DETAILS = 'SET_VACCINE_DETAILS';
export const SET_VACCINE_BLOG = 'SET_VACCINE_BLOG';
export const SET_EXAM_LAB_BLOG = 'SET_EXAM_LAB_BLOG';
export const SET_EXAM_IMG_BLOG = 'SET_EXAM_IMG_BLOG';
export const SET_SEO_TAGS = 'SET_SEO_TAGS';

export const ASYNC_GET_STRUCTURE = 'ASYNC_GET_STRUCTURE';
export const ASYNC_GET_BANNER = 'ASYNC_GET_BANNER';
export const ASYNC_GET_SCHEDULE_CARDS = 'ASYNC_GET_SCHEDULE_CARDS';
export const ASYNC_GET_QUICK_ACCESS_CARDS = 'ASYNC_GET_QUICK_ACCESS_CARDS';
export const ASYNC_GET_SERVICE_CARDS = 'ASYNC_GET_SERVICE_CARDS';
export const ASYNC_GET_FOOTER = 'ASYNC_GET_FOOTER';
export const ASYNC_GET_UNIT_DETAILS = 'ASYNC_GET_UNIT_DETAILS';
export const ASYNC_GET_INSTITUTIONAL = 'ASYNC_GET_INSTITUTIONAL';
export const ASYNC_GET_VACCINE_DETAILS = 'ASYNC_GET_VACCINE_DETAILS';
export const ASYNC_GET_VACCINE_BLOG = 'ASYNC_GET_VACCINE_BLOG';
export const ASYNC_GET_EXAM_LAB_BLOG = 'ASYNC_GET_EXAM_LAB_BLOG';
export const ASYNC_GET_EXAM_IMG_BLOG = 'ASYNC_GET_EXAM_IMG_BLOG';
export const ASYNC_GET_SEO_TAGS = 'ASYNC_GET_SEO_TAGS';
