import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  searchBar: '',
  listUnits: [],
  listUnitFiltered: [],
  servicesFilter: [],
  facilitiesFilter: [],
  selectedUnit: null,
  listOpenState: false,
  isLoading: false,
};

export const { Types, Creators } = createActions({
  setSearchBar: ['payload'],
  requestListUnits: ['payload'],
  requestListUnitsSuccess: ['payload'],
  requestListUnitsFail: ['error'],
  setUnitDetail: ['payload'],
  listUnitsFilter: ['payload'],
  listUnitsFilterSuccess: ['payload'],
  listUnitsFilterFail: ['error'],
  addFacilitiesFilter: ['payload'],
  removeFacilitiesFilter: ['payload'],
  addServicesFilter: ['payload'],
  removeServicesFilter: ['payload'],
  setListUnitOpen: ['payload'],
});

const setSearchBar = (state, { payload }) => ({
  ...state,
  searchBar: payload,
});

const requestListUnits = (state) => ({
  ...state,
  isLoading: true,
});

const requestListUnitsSuccess = (state, { payload }) => ({
  ...state,
  listUnits: payload,
  listUnitFiltered: payload,
  isLoading: false,
});

const requestListUnitsFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const listUnitsFilter = (state) => ({
  ...state,
  isLoading: true,
});

const listUnitsFilterSuccess = (state, { payload }) => ({
  ...state,
  listUnitFiltered: payload,
  isLoading: false,
});

const listUnitsFilterFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const addFacilitiesFilter = (state, { payload }) => ({
  ...state,
  facilitiesFilter: [...state.facilitiesFilter, payload],
});

const removeFacilitiesFilter = (state, { payload }) => ({
  ...state,
  facilitiesFilter: state.facilitiesFilter.filter(
    (item) => item.id !== payload
  ),
});

const addServicesFilter = (state, { payload }) => ({
  ...state,
  servicesFilter: [...state.servicesFilter, payload],
});

const removeServicesFilter = (state, { payload }) => ({
  ...state,
  servicesFilter: state.servicesFilter.filter((item) => item.id !== payload),
});

const setListUnitOpen = (state, { payload }) => ({
  ...state,
  listOpenState: payload,
  isLoading: false,
});

const setUnitDetail = (state, { payload }) => ({
  ...state,
  selectedUnit: payload,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_SEARCH_BAR]: setSearchBar,
  [Types.REQUEST_LIST_UNITS]: requestListUnits,
  [Types.REQUEST_LIST_UNITS_SUCCESS]: requestListUnitsSuccess,
  [Types.REQUEST_LIST_UNITS_FAIL]: requestListUnitsFail,
  [Types.LIST_UNITS_FILTER]: listUnitsFilter,
  [Types.LIST_UNITS_FILTER_SUCCESS]: listUnitsFilterSuccess,
  [Types.LIST_UNITS_FILTER_FAIL]: listUnitsFilterFail,
  [Types.ADD_FACILITIES_FILTER]: addFacilitiesFilter,
  [Types.REMOVE_FACILITIES_FILTER]: removeFacilitiesFilter,
  [Types.ADD_SERVICES_FILTER]: addServicesFilter,
  [Types.REMOVE_SERVICES_FILTER]: removeServicesFilter,
  [Types.SET_LIST_UNIT_OPEN]: setListUnitOpen,
  [Types.SET_UNIT_DETAIL]: setUnitDetail,
});
