import {
  SET_DEFAULT_LOCATION,
  SET_SITE_HOSTNAME,
  SET_GENERAL_ERROR,
  SET_LOCATION_STATE,
  SET_GENERIC_SEARCH
} from 'utils/constants/actions/actionTypes';
import { constants } from 'utils/constants';

const INITIAL_STATE = {
  coords: { lat: -19.9209296, lng: -43.9387409 },
  hostname: '',
  msgGeneralError: '',
  locationState: (localStorage.getItem(constants.LOCATION_STATE)
    ? localStorage.getItem(constants.LOCATION_STATE)
    : 'MG'),
  genericSearch: []
};

const AppReducer = (state, action) => {
  if (!state) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case SET_GENERAL_ERROR:
      return {
        ...state,
        msgGeneralError: action.payload
      };

    case SET_DEFAULT_LOCATION:
      return {
        ...state,
        coords: action.payload
      };

    case SET_SITE_HOSTNAME:
      return {
        ...state,
        hostname: action.payload
      };

    case SET_LOCATION_STATE:
      return {
        ...state,
        locationState: action.payload
      };

    case SET_GENERIC_SEARCH:
      return {
        ...state,
        genericSearch: action.payload
      };

    default:
      return state;
  }
};

export default AppReducer;
