import React from 'react';
import { useSelector } from 'react-redux';
import './Loader.scss';

const Loader = () => {
  const { structure } = useSelector(state => state.TemplateReducer);
  const color =
    structure && Object.keys(structure).length > 0
      ? structure.primary_color
      : '#D31245';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="loader"
      width="100px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      data-testid="Loader"
    >
      <defs>
        <filter
          id="ldio-pa3k5uir79c-filter"
          x="-100%"
          y="-100%"
          width="300%"
          height="300%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
          <feComponentTransfer result="cutoff">
            <feFuncA type="linear" slope="125" intercept="-75" />
          </feComponentTransfer>
        </filter>
      </defs>
      <g filter="url(#ldio-pa3k5uir79c-filter)">
        <circle cx="20.2107" cy="50" r="18.2107" fill={color}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="18;25;18"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
          />
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="20;27;20"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
          />
        </circle>
        <circle cx="66.2107" cy="50" r="24.7893" fill={color}>
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="25;18;25"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
          />
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="66;73;66"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
          />
        </circle>
      </g>
    </svg>
  );
};

export default Loader;
