import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  listUnits: [],
  listUnitFiltered: [],
  serviceDetails: {},
  serviceList: [],
  cardsHome: [],
  isLoading: false,
  isLoadingCards: false,
};

export const { Types, Creators } = createActions({
  requestSearchUnit: ['payload'],
  requestSearchUnitSuccess: ['payload'],
  requestSearchUnitFail: ['error'],
  requestListUnitService: ['payload'],
  requestListUnitServiceSuccess: ['payload'],
  requestListUnitServiceFail: ['error'],
  requestServicesList: ['key', 'typeService'],
  requestServicesListSuccess: ['payload'],
  requestServicesListFail: ['error'],
  requestServiceDetails: ['payload'],
  requestServiceDetailsSuccess: ['payload'],
  requestServiceDetailsFail: ['error'],
  setQuickAccessCards: ['typeService', 'disregardItems'],
  setQuickAccessCardsSuccess: ['payload'],
  setQuickAccessCardsFail: ['error'],
  clearServices: [],
  clearQuickAccessCards: [],
});

const requestSearchUnit = (state) => ({
  ...state,
  isLoading: true,
});

const requestSearchUnitSuccess = (state, { payload }) => ({
  ...state,
  listUnitFiltered: payload,
  isLoading: false,
});

const requestSearchUnitFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const requestListUnitService = (state) => ({
  ...state,
  isLoading: true,
});

const requestListUnitServiceSuccess = (state, { payload }) => ({
  ...state,
  listUnits: payload,
  listUnitFiltered: payload,
  isLoading: false,
});

const requestListUnitServiceFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const requestServicesList = (state) => ({
  ...state,
  isLoading: true,
});

const requestServicesListSuccess = (state, { payload }) => ({
  ...state,
  serviceList: payload,
  isLoading: false,
});

const requestServicesListFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const requestServiceDetails = (state) => ({
  ...state,
  isLoading: true,
});

const requestServiceDetailsSuccess = (state, { payload }) => ({
  ...state,
  serviceDetails: payload,
  isLoading: false,
});

const requestServiceDetailsFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

const clearServices = (state) => ({
  ...state,
  listUnits: [],
  listUnitFiltered: [],
  serviceDetails: {},
  serviceList: [],
});

const setQuickAccessCards = (state) => ({
  ...state,
  isLoadingCards: true,
});

const setQuickAccessCardsSuccess = (state, { payload }) => ({
  ...state,
  cardsHome: [...state.cardsHome, payload],
  isLoadingCards: false,
});

const setQuickAccessCardsFail = (state, { error }) => ({
  ...state,
  isLoadingCards: false,
  error,
});

const clearQuickAccessCards = (state) => ({
  ...state,
  cardsHome: [],
});

export default createReducer(INITIAL_STATE, {
  [Types.REQUEST_SEARCH_UNIT]: requestSearchUnit,
  [Types.REQUEST_SEARCH_UNIT_SUCCESS]: requestSearchUnitSuccess,
  [Types.REQUEST_SEARCH_UNIT_FAIL]: requestSearchUnitFail,
  [Types.REQUEST_LIST_UNIT_SERVICE]: requestListUnitService,
  [Types.REQUEST_LIST_UNIT_SERVICE_SUCCESS]: requestListUnitServiceSuccess,
  [Types.REQUEST_LIST_UNIT_SERVICE_FAIL]: requestListUnitServiceFail,
  [Types.REQUEST_SERVICE_DETAILS]: requestServiceDetails,
  [Types.REQUEST_SERVICE_DETAILS_SUCCESS]: requestServiceDetailsSuccess,
  [Types.REQUEST_SERVICE_DETAILS_FAIL]: requestServiceDetailsFail,
  [Types.REQUEST_SERVICES_LIST]: requestServicesList,
  [Types.REQUEST_SERVICES_LIST_SUCCESS]: requestServicesListSuccess,
  [Types.REQUEST_SERVICES_LIST_FAIL]: requestServicesListFail,
  [Types.SET_QUICK_ACCESS_CARDS]: setQuickAccessCards,
  [Types.SET_QUICK_ACCESS_CARDS_SUCCESS]: setQuickAccessCardsSuccess,
  [Types.SET_QUICK_ACCESS_CARDS_FAIL]: setQuickAccessCardsFail,
  [Types.CLEAR_SERVICES]: clearServices,
  [Types.CLEAR_QUICK_ACCESS_CARDS]: clearQuickAccessCards,
});
