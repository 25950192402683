import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import {
  clearTemplate,
  getBanner,
  getFooter,
  getInstitutional,
  getQuickAccessCards,
  getScheduleCards,
  getSeoTags,
  getServicesCards,
  getStructure,
  getUnitDetails,
  getVaccineDetails,
} from 'utils/constants/actions/Template';
import { constants, routes } from 'utils/constants';
import {
  setDefaultLocation,
  setSiteHostname,
} from 'utils/constants/actions/App';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/core/Loader';
import { ToastContainer } from 'react-toastify';
import { defChatHelper } from 'utils/helpers/ChatHelper';
import { identifyDomains } from 'utils/functions';

const AppContainer = lazy(() => import('components/core/AppContainer'));
const Footer = lazy(() => import('components/presentation/footer/Footer'));
const Header = lazy(() => import('components/presentation/header/Header'));
const HeaderPardini = lazy(() =>
  import('components/presentation/header/HeaderPardini')
);
const MetaTags = lazy(() => import('components/core/MetaTags'));
const PageError = lazy(() => import('components/core/PageError'));
const ScrollToTop = lazy(() => import('components/core/ScrollToTop'));
const TopBar = lazy(() => import('components/core/TopBar'));

const ExamLab = lazy(() => import('containers/ExamLab'));
const ExamImg = lazy(() => import('containers/ExamImg'));
const ExamMetGraf = lazy(() => import('containers/ExamMetGraf'));
const Home = lazy(() => import('containers/Home'));
const Institutional = lazy(() => import('containers/Institutional'));
const Service = lazy(() => import('containers/Service'));
const Unit = lazy(() => import('containers/Unit'));
const Vaccine = lazy(() => import('containers/Vaccine'));
const Contact = lazy(() => import('containers/Contact'));
const SocialEventsProtocol = lazy(() =>
  import('containers/SocialEventsProtocol')
);
const AmilClients = lazy(() => import('containers/AmilClients'));
const Estimate = lazy(() => import('containers/Estimate'));
const HowToAccess = lazy(() => import('containers/HowToAccess'));
const PermanentPassword = lazy(() => import('containers/PermanentPassword'));

function App() {
  const dispatch = useDispatch();
  const {
    structure,
    banner,
    scheduleCards,
    quickAccessCards,
    unitDetails,
    serviceCards,
    institutional,
    vaccineDetails,
    footer,
    seoTags,
  } = useSelector((state) => state.TemplateReducer);
  const { hostname, locationState, msgGeneralError } = useSelector(
    (state) => state.AppReducer
  );
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const domain = identifyDomains();
    if (domain) dispatch(setSiteHostname(domain));
  }, [dispatch]);

  useEffect(() => {
    if (hostname.length > 0) {
      dispatch(clearTemplate());
      setLoader(true);
      dispatch(getStructure());
      dispatch(getBanner());
      dispatch(getScheduleCards());
      dispatch(getQuickAccessCards());
      dispatch(getServicesCards());
      dispatch(getUnitDetails());
      dispatch(getInstitutional());
      dispatch(getVaccineDetails());
      dispatch(getFooter());
      dispatch(getSeoTags());

      if (
        hostname === 'hermespardini' &&
        process.env.NODE_ENV === 'production'
      ) {
        TagManager.initialize({
          gtmId: 'GTM-TTWPWC2',
        });
      }
      if (hostname === 'ecoar' && process.env.NODE_ENV === 'production') {
        TagManager.initialize({
          gtmId: 'GTM-MT3PJ3B',
        });
      }
      if (hostname === 'centrodemed' && process.env.NODE_ENV === 'production') {
        TagManager.initialize({
          gtmId: 'GTM-MGMDQ8L',
        });
      }
    }
  }, [hostname, locationState, dispatch]);

  const validateContent = () =>
    (hostname.length > 0 &&
      Object.keys(structure).length > 0 &&
      banner.length > 0 &&
      scheduleCards.length > 0 &&
      quickAccessCards.length > 0 &&
      Object.keys(unitDetails).length > 0 &&
      serviceCards.length > 0 &&
      institutional.length > 0 &&
      ((structure.vaccine === '1' && Object.keys(vaccineDetails).length > 0) ||
        structure.vaccine === '0') &&
      Object.keys(footer).length > 0) ||
    msgGeneralError.length > 0;

  useEffect(() => {
    window.prerenderReady = validateContent();
    setLoader(!validateContent());

    if (validateContent()) {
      defChatHelper(locationState);
    }
  }, [
    hostname,
    structure,
    banner,
    scheduleCards,
    quickAccessCards,
    unitDetails,
    serviceCards,
    institutional,
    vaccineDetails,
    footer,
    seoTags,
    msgGeneralError,
    locationState,
  ]);

  useEffect(() => {
    if (Object.keys(unitDetails).length > 0) {
      if (localStorage.getItem('showActiveLocation') === 'active') {
        const successCoords = (pos) => {
          dispatch(
            setDefaultLocation({
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            })
          );
        };
        navigator.geolocation.watchPosition(successCoords, null, {
          enableHighAccuracy: true,
        });
      } else {
        dispatch(
          setDefaultLocation({
            lat: Number(unitDetails.lat),
            lng: Number(unitDetails.lng),
          })
        );
      }
    }
  }, [unitDetails, dispatch]);

  if (loader) {
    return (
      <div className="app">
        <div className="app__loader">
          <div>
            <Loader />
            <span>Carregando Informações do site...</span>
          </div>
        </div>
      </div>
    );
  }

  if (msgGeneralError.length > 0) {
    return (
      <div className="app">
        <PageError
          title="Ocorreu uma falha ao carregar o site."
          text={msgGeneralError}
        />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="app">
            <div className="app__loader">
              <div>
                <Loader />
                <span>Carregando...</span>
              </div>
            </div>
          </div>
        }
      >
        <MetaTags page="HOME" seoTags={seoTags} siteName={hostname}>
          <link
            type="image/x-icon"
            rel="shortcut icon"
            href={structure.media_favicon}
          />
          <title>{structure.site_title}</title>
        </MetaTags>

        <ScrollToTop />
        {structure.topbar === '1' && (
          <TopBar
            color={structure.topbar_color}
            text={structure.topbar_text}
            linktext={` ${structure.topbar_link_text}`}
            link={structure.topbar_link}
          />
        )}
        {hostname === constants.SITE_PARDINI ? <HeaderPardini /> : <Header />}
        <AppContainer>
          <ToastContainer />
          <Switch>
            <Route path={routes.HOME} exact component={Home} />
            <Route path={routes.HOW_TO_ACCESS} exact component={HowToAccess} />

            {(hostname === constants.SITE_PARDINI ||
              hostname === constants.SITE_ECOAR ||
              hostname === constants.SITE_CMNG) && (
              <Route
                path={routes.PERMANENT_PASSWORD}
                exact
                component={PermanentPassword}
              />
            )}

            <Route path={routes.UNIT_DETAILS} exact component={Unit} />
            <Route path={routes.CONTACT_US} component={Contact} />
            {hostname === constants.SITE_PARDINI && (
              <Route
                path={routes.SOCIAL_EVENTS_PROTOCOL}
                component={SocialEventsProtocol}
              />
            )}
            {hostname === constants.SITE_PARDINI && (
              <Route path={routes.AMIL_CLIENTS} component={AmilClients} />
            )}
            <Route path={routes.ESTIMATE} component={Estimate} />
            {hostname === constants.SITE_PARDINI && (
              <Route
                path={routes.UNIT_DETAILS_FEDERATION}
                exact
                component={Unit}
              />
            )}
            <Route path={routes.UNIT} exact component={Unit} />
            {structure.laboratory === '1' && (
              <Route path={routes.EXAM_LAB} exact component={ExamLab} />
            )}
            {structure.image === '1' && (
              <Route path={routes.EXAM_IMG} exact component={ExamImg} />
            )}
            {structure.image === '1' && (
              <Route
                path={routes.EXAM_MET_GRAF}
                exact
                component={ExamMetGraf}
              />
            )}
            {structure.vaccine === '1' && (
              <Route path={routes.VACCINE_TRAIL} exact component={Vaccine}>
                <Redirect to={routes.VACCINE} />
              </Route>
            )}
            {structure.vaccine === '1' && (
              <Route
                path={routes.VACCINE_TRAIL_DETAILS}
                exact
                component={Vaccine}
              />
            )}
            {structure.vaccine === '1' && (
              <Route path={routes.VACCINE} exact component={Vaccine} />
            )}
            <Route path={routes.SERVICE} exact component={Service} />
            <Route path={routes.SERVICE_ANCHOR} exact component={Service} />
            <Route
              path={routes.INSTITUTIONAL_ANCHOR}
              exact
              component={Institutional}
            />
            <Route
              path="*"
              component={() => <PageError title="Página não encontrada" />}
            />
          </Switch>
        </AppContainer>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
