/* eslint-disable camelcase */
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { Types as Actions } from 'store/ducks/Services';
import * as endpoint from 'utils/constants/endpoints';
import * as constants from 'utils/constants/constants';
import { requestAPI } from 'utils/helpers/RequestHelper';
import { getQuickAccessCardsItem } from 'utils/helpers/ServicesHelper';
import { addDistanceToArray } from 'utils/functions';

export const getAppState = (state) => state.AppReducer;
export const getStructureState = (state) => state.TemplateReducer;
export const getServiceState = (state) => state.Services;

function* fetchUnit() {
  try {
    const { structure } = yield select(getStructureState);
    const { coords } = yield select(getAppState);
    const resource = yield requestAPI({
      font: constants.PARDINI_API,
      verb: 'POST',
      endpoint: endpoint.UNITS,
      data: {
        empresa: Number(structure.company_id),
        unidade: '',
        situacao: 'A',
      },
    });
    const result = resource.data.ConsultaResult.unidades;
    addDistanceToArray(result, coords);
    return result;
  } catch {
    return [];
  }
}

function* requestListUnitService({ payload }) {
  const { structure } = yield select(getStructureState);
  const unitDetails = yield call(fetchUnit);
  try {
    const resource = yield requestAPI({
      font: constants.PARDINI_API,
      verb: 'GET',
      endpoint: endpoint.UNIT_SERVICES,
      query: `exam=${payload}`,
    });
    const filterUnitList = resource.data.unidades.filter(
      (unit) => unit.IdEmpresa === Number(structure.company_id)
    );

    const result = unitDetails.filter((unit) =>
      filterUnitList.find((item) => item.IdUnidade === unit.codigo)
    );

    yield put({
      type: Actions.REQUEST_LIST_UNIT_SERVICE_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({ type: Actions.REQUEST_LIST_UNIT_SERVICE_FAIL, error });
  }
}

function* requestServiceDetails(action) {
  try {
    const resource = yield requestAPI({
      font: constants.PARDINI_API,
      verb: 'GET',
      endpoint: endpoint.SERVICE_DETAILS,
      id: action.payload,
    });
    const result = resource.data;
    yield put({
      type: Actions.REQUEST_SERVICE_DETAILS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Actions.REQUEST_SERVICE_DETAILS_FAILL,
      error,
    });
  }
}

function* requestServicesList({ key, typeService }) {
  if (key) {
    try {
      const { structure } = yield select(getStructureState);
      const keyword = key.replace(/\s+/g, '+');
      const resource = yield requestAPI({
        font: constants.PARDINI_API,
        verb: 'GET',
        endpoint: endpoint.SERVICES_LIST,
        query: `exam=${keyword}&Estado=${structure.state}&Empresa=${structure.company_id}`,
      });
      const result = resource.data.ExamesEncontrados.filter(
        (itemService) => itemService.ExameServico === typeService.toUpperCase()
      );
      yield put({
        type: Actions.REQUEST_SERVICES_LIST_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({
        type: Actions.REQUEST_SERVICES_LIST_FAIL,
        error,
      });
    }
  }
}

function* setQuickAccessCards({ typeService, disregardItems }) {
  try {
    const { cardsHome } = yield select(getServiceState);
    const { hostname, locationState } = yield select(getAppState);
    let finalResult = [];

    if (!cardsHome.some((card) => card.cardId === typeService)) {
      switch (typeService) {
        case constants.CARD_IMG: {
          finalResult = yield call(
            getQuickAccessCardsItem,
            constants.MOST_ACCESS_EXAM_IMG,
            typeService,
            disregardItems,
            hostname,
            locationState
          );
          break;
        }
        case constants.CARD_LAB: {
          finalResult = yield call(
            getQuickAccessCardsItem,
            constants.MOST_ACCESS_EXAM_LAB,
            typeService,
            disregardItems,
            hostname,
            locationState
          );
          break;
        }
        case constants.CARD_VAC: {
          finalResult = yield call(
            getQuickAccessCardsItem,
            constants.MOST_ACCESS_VACCINE,
            typeService,
            disregardItems,
            hostname,
            locationState
          );
          break;
        }
        case constants.CARD_UNIT: {
          const units = yield call(fetchUnit);
          finalResult = yield call(
            getQuickAccessCardsItem,
            constants.MOST_ACCESS_UNIT,
            typeService,
            disregardItems,
            hostname,
            locationState,
            units
          );
          break;
        }
        default:
          break;
      }
    }
    yield put({
      type: Actions.SET_QUICK_ACCESS_CARDS_SUCCESS,
      payload: finalResult[0],
    });
  } catch (error) {
    yield put({
      type: Actions.SET_QUICK_ACCESS_CARDS_FAIL,
      error,
    });
  }
}

function* requestSearchUnit({ payload }) {
  const { listUnits } = yield select(getServiceState);
  if (payload) {
    try {
      const filterListUnit = () =>
        listUnits.filter(
          (unit) =>
            unit.endereco
              .toLowerCase()
              .replace(/\s/g, '')
              .concat(
                unit.cidade.toLowerCase().replace(/\s/g, ''),
                unit.nome.toLowerCase().replace(/\s/g, ''),
                String(unit.cep)
              )
              .indexOf(payload.toLowerCase().replace(/\s/g, '')) > -1
        );
      const result = filterListUnit();
      yield put({
        type: Actions.REQUEST_SEARCH_UNIT_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({
        type: Actions.REQUEST_SEARCH_UNIT_FAIL,
        error,
      });
    }
  } else {
    yield put({
      type: Actions.REQUEST_SEARCH_UNIT_FAIL,
      payload: listUnits,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Actions.REQUEST_SERVICES_LIST, requestServicesList);
  yield takeLatest(Actions.REQUEST_SEARCH_UNIT, requestSearchUnit);
  yield takeLatest(Actions.REQUEST_LIST_UNIT_SERVICE, requestListUnitService);
  yield takeLatest(Actions.REQUEST_SERVICE_DETAILS, requestServiceDetails);
  yield takeLatest(Actions.SET_QUICK_ACCESS_CARDS, setQuickAccessCards);
}
