import { takeLatest, put, select } from 'redux-saga/effects';
import * as Types from 'utils/constants/actions/actionTypes';
import * as endpoint from 'utils/constants/endpoints';
import * as constants from 'utils/constants/constants';
import { requestAPI } from 'utils/helpers/RequestHelper';

export const getStructure = state => state.TemplateReducer.structure;

function* getGenericSearch({ payload }) {
  if (payload.length > 0) {
    try {
      const keyword = payload.replace(/\s+/g, '+');
      const structure = yield select(getStructure);
      const result = [];
      const resource = yield requestAPI({
        font: constants.PARDINI_API,
        verb: 'GET',
        endpoint: endpoint.GENERIC_SEARCH,
        query: `company=${structure.company_id}&search=${keyword}`,
      });

      resource.data.Exames.forEach(item => {
        result.push({
          id: item.ID,
          name: item.Nome,
          type: item.ExameServico,
        });
      });

      resource.data.Unidades.forEach(item => {
        result.push({
          id: item.ID,
          name: item.Nome,
          type: 'UNIT',
        });
      });

      yield put({ type: Types.SET_GENERIC_SEARCH, payload: result });
    } catch (error) {
      yield put({ type: Types.SET_GENERIC_SEARCH, payload: [] });
    }
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.ASYNC_SET_GENERIC_SEARCH, getGenericSearch);
}
