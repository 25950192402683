import { constants } from 'utils/constants';
import { getMostAccessed } from 'utils/functions';
import { services } from './data.json';

export const mostAccessedHelper = (page) => {
  switch (page) {
    case constants.PAGE_IMG:
      return constants.MOST_ACCESS_EXAM_IMG;
    case constants.PAGE_LAB:
      return constants.MOST_ACCESS_EXAM_LAB;
    case constants.PAGE_VAC:
      return constants.MOST_ACCESS_VACCINE;
    case constants.PAGE_UNIT:
      return constants.MOST_ACCESS_UNIT;
    default:
      return '';
  }
};

export const titleCard = (type) => {
  switch (type) {
    case constants.CARD_LAB:
      return 'Exames Laboratoriais';
    case constants.CARD_IMG:
      return 'Exames por Imagem';
    case constants.CARD_VAC:
      return 'Vacinas';
    case constants.CARD_UNIT:
      return 'Unidades';
    case constants.CARD_MEGR:
      return 'Métodos Gráficos';
    default:
      return '';
  }
};

export const getQuickAccessCardsItem = (
  key,
  card,
  disregardItems,
  hostname,
  stateLocation,
  units
) => {
  let itemsResult = [];
  const finalResult = [];
  let totalItems = 0;
  let resourceFile = [];
  const keyLocalStorage =
    hostname === constants.SITE_PARDINI ? `${key}_${stateLocation}` : key;
  const mostAccess = getMostAccessed(keyLocalStorage, disregardItems);

  if (card !== constants.CARD_UNIT) {
    if (hostname === constants.SITE_PARDINI) {
      resourceFile = services.find(
        (item) =>
          item.site === hostname &&
          item.state === stateLocation &&
          item.id === card
      );
    } else {
      resourceFile = services.find(
        (item) => item.site === hostname && item.id === card
      );
    }
  }

  totalItems = mostAccess.length + disregardItems.length;
  finalResult.push({ cardId: card, items: mostAccess });

  if (card !== constants.CARD_UNIT) {
    itemsResult = resourceFile.items
      .filter((service) =>
        disregardItems.every((disregardItem) => service.id !== disregardItem.id)
      )
      .filter((service) =>
        mostAccess.every((localAccess) => service.id !== localAccess.id)
      )
      .slice(0, constants.QUANT_ITEMS_CARD - totalItems);
  } else {
    itemsResult = units
      .filter((unit) =>
        disregardItems.every(
          (disregardItem) => unit.codigo !== disregardItem.id
        )
      )
      .filter((unit) =>
        mostAccess.every((localAccess) => unit.codigo !== localAccess.id)
      )
      .slice(0, constants.QUANT_ITEMS_CARD - totalItems);
  }

  if (constants.QUANT_ITEMS_CARD - totalItems > 0) {
    itemsResult.forEach((item) => {
      if (card !== constants.CARD_UNIT) {
        finalResult[0].items.push({
          id: item.id,
          name: item.name,
          access: 0,
        });
      } else {
        finalResult[0].items.push({
          id: item.codigo,
          name: item.nome,
          access: 0,
        });
      }
    });
  }
  return finalResult;
};
