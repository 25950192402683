import {
  ASYNC_GET_STRUCTURE,
  ASYNC_GET_BANNER,
  ASYNC_GET_SCHEDULE_CARDS,
  ASYNC_GET_QUICK_ACCESS_CARDS,
  ASYNC_GET_SERVICE_CARDS,
  ASYNC_GET_FOOTER,
  ASYNC_GET_UNIT_DETAILS,
  ASYNC_GET_INSTITUTIONAL,
  ASYNC_GET_VACCINE_DETAILS,
  ASYNC_GET_VACCINE_BLOG,
  ASYNC_GET_EXAM_LAB_BLOG,
  ASYNC_GET_EXAM_IMG_BLOG,
  ASYNC_GET_SEO_TAGS,
  CLEAR_TEMPLATE
} from './actionTypes';

export const clearTemplate = () => ({ type: CLEAR_TEMPLATE });

export const getStructure = () => ({ type: ASYNC_GET_STRUCTURE });

export const getBanner = () => ({ type: ASYNC_GET_BANNER });

export const getScheduleCards = () => ({ type: ASYNC_GET_SCHEDULE_CARDS });

export const getQuickAccessCards = () => ({
  type: ASYNC_GET_QUICK_ACCESS_CARDS
});

export const getServicesCards = () => ({ type: ASYNC_GET_SERVICE_CARDS });

export const getUnitDetails = () => ({ type: ASYNC_GET_UNIT_DETAILS });

export const getInstitutional = () => ({ type: ASYNC_GET_INSTITUTIONAL });

export const getFooter = () => ({ type: ASYNC_GET_FOOTER });

export const getVaccineDetails = () => ({ type: ASYNC_GET_VACCINE_DETAILS });

export const getBlog = (value) => ({ type: ASYNC_GET_VACCINE_BLOG, payload: value });

export const getVaccineBlog = (value) => ({ type: ASYNC_GET_VACCINE_BLOG, payload: value });

export const getExamLabBlog = (value) => ({ type: ASYNC_GET_EXAM_LAB_BLOG, payload: value });

export const getExamImgBlog = (value) => ({ type: ASYNC_GET_EXAM_IMG_BLOG, payload: value });

export const getSeoTags = () => ({ type: ASYNC_GET_SEO_TAGS });
