import axios from 'axios';
import { BASE_URL_CMS } from 'utils/constants/routes';
import { toast } from 'react-toastify';

const CmsApi = axios.create({
  baseURL: BASE_URL_CMS,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  },
});

CmsApi.interceptors.response.use(
  (response) => response,
  (error) => {
    toast(
      'Não é possível realizar essa consulta. Por favor tente mais tarde.',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    return Promise.reject(error);
  }
);

export default CmsApi;
