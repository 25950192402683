import {
  SET_STRUCTURE,
  SET_BANNER,
  SET_SCHEDULE_CARDS,
  SET_QUICK_ACCESS,
  SET_SERVICE_CARDS,
  SET_FOOTER,
  SET_UNIT_DETAILS,
  SET_INSTITUTIONAL,
  SET_VACCINE_DETAILS,
  SET_EXAM_LAB_BLOG,
  SET_EXAM_IMG_BLOG,
  SET_VACCINE_BLOG,
  SET_SEO_TAGS,
  CLEAR_TEMPLATE,
} from 'utils/constants/actions/actionTypes';

const INITIAL_STATE = {
  structure: {},
  banner: [],
  scheduleCards: [],
  quickAccessCards: [],
  serviceCards: [],
  institutional: [],
  footer: {},
  unitDetails: {},
  vaccineDetails: {},
  vaccineBlog: [],
  examLabBlog: [],
  examImgBlog: [],
  seoTags: [],
};

const TemplateReducer = (state, action) => {
  if (!state) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case CLEAR_TEMPLATE:
      return {
        ...state,
        INITIAL_STATE,
      };

    case SET_STRUCTURE:
      return {
        ...state,
        structure: action.payload,
      };

    case SET_BANNER:
      return {
        ...state,
        banner: action.payload,
      };

    case SET_SCHEDULE_CARDS:
      return {
        ...state,
        scheduleCards: action.payload,
      };

    case SET_QUICK_ACCESS:
      return {
        ...state,
        quickAccessCards: action.payload,
      };

    case SET_SERVICE_CARDS:
      return {
        ...state,
        serviceCards: action.payload,
      };

    case SET_INSTITUTIONAL:
      return {
        ...state,
        institutional: action.payload,
      };

    case SET_FOOTER:
      return {
        ...state,
        footer: action.payload,
      };

    case SET_UNIT_DETAILS:
      return {
        ...state,
        unitDetails: action.payload,
      };

    case SET_VACCINE_DETAILS:
      return {
        ...state,
        vaccineDetails: action.payload,
      };

    case SET_VACCINE_BLOG:
      return {
        ...state,
        vaccineBlog: action.payload,
      };

    case SET_EXAM_IMG_BLOG:
      return {
        ...state,
        examImgBlog: action.payload,
      };

    case SET_EXAM_LAB_BLOG:
      return {
        ...state,
        examLabBlog: action.payload,
      };

    case SET_SEO_TAGS:
      return {
        ...state,
        seoTags: action.payload,
      };

    default:
      return state;
  }
};

export default TemplateReducer;
