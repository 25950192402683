import { takeLatest, put, select } from 'redux-saga/effects';
import { requestAPI } from 'utils/helpers/RequestHelper';
import { Types as Actions } from 'store/ducks/Units';
import * as endpoint from 'utils/constants/endpoints';
import * as constants from 'utils/constants/constants';
import { addDistanceToArray } from 'utils/functions';

export const getAppReducer = (state) => state.AppReducer;
export const getUnitsState = (state) => state.Units;

function* requestListUnits({ payload }) {
  try {
    const { coords } = yield select(getAppReducer);
    const resource = yield requestAPI({
      font: constants.PARDINI_API,
      verb: 'POST',
      endpoint: endpoint.UNITS,
      data: {
        empresa: Number(payload),
        unidade: '',
        situacao: 'A',
      },
    });
    const result = resource.data.ConsultaResult.unidades;
    addDistanceToArray(result, coords);
    yield put({ type: Actions.REQUEST_LIST_UNITS_SUCCESS, payload: result });
  } catch {
    yield put({
      type: Actions.REQUEST_LIST_UNITS_FAIL,
      payload: [],
    });
  }
}

function* listUnitsFilter() {
  try {
    const {
      facilitiesFilter,
      servicesFilter,
      searchBar,
      listUnits,
    } = yield select(getUnitsState);

    const result = listUnits.filter(
      (unit) =>
        unit.endereco
          .toLowerCase()
          .replace(/\s/g, '')
          .concat(
            unit.cidade.toLowerCase().replace(/\s/g, ''),
            unit.nome.toLowerCase().replace(/\s/g, ''),
            String(unit.cep)
          )
          .indexOf(searchBar.toLowerCase().replace(/\s/g, '')) > -1 &&
        (unit.servicos &&
          servicesFilter.every((services) =>
            unit.servicos.find(
              (unitServices) => services.id === unitServices.id
            )
          )) &&
        (unit.amenidades &&
          facilitiesFilter.every((facilities) =>
            unit.amenidades.find(
              (unitFacilities) => facilities.id === unitFacilities.id
            )
          ))
    );
    yield put({
      type: Actions.LIST_UNITS_FILTER_SUCCESS,
      payload: result,
    });
  } catch {
    yield put({
      type: Actions.LIST_UNITS_FILTER_FAIL,
      payload: [],
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Actions.REQUEST_LIST_UNITS, requestListUnits);
  yield takeLatest(Actions.LIST_UNITS_FILTER, listUnitsFilter);
}
