import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL_PARDINI } from 'utils/constants/routes';
import { TOKEN_PARDINI } from 'utils/constants/endpoints';
import { hasToken, decryptToken } from 'utils/functions/functions';
import TokenApi from 'utils/constants/api/TokenApi';

const PardiniApi = axios.create({
  baseURL: BASE_URL_PARDINI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  },
});

PardiniApi.interceptors.request.use(async (config) => {
  const newConfig = config;
  if (hasToken('token')) {
    const { token } = decryptToken('token');
    newConfig.headers.Authorization = token;
  } else {
    try {
      const response = await TokenApi.post(TOKEN_PARDINI)
        .then((res) => res)
        .catch((error) => {
          throw error;
        });
      if (newConfig.data) {
        newConfig.headers.Authorization = response.data.TokenResult.token;
      }
    } catch {
      toast(
        'Ocorreu um erro e a requisição não pode ser completada. Tente novamente.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  }
  return newConfig;
});

PardiniApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

export default PardiniApi;
