import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL_PARDINI_HOMOLOG } from 'utils/constants/routes';
import { TOKEN_PARDINI } from 'utils/constants/endpoints';
import { hasToken, decryptToken } from 'utils/functions/functions';
import TokenHomologApi from 'utils/constants/api/TokenHomologApi';

const PardiniHomologApi = axios.create({
  baseURL: BASE_URL_PARDINI_HOMOLOG,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  },
});

PardiniHomologApi.interceptors.request.use(async (config) => {
  const newConfig = config;
  if (hasToken('token_homol')) {
    const { token } = decryptToken('token_homol');
    newConfig.headers.Authorization = token;
  } else {
    try {
      const response = await TokenHomologApi.post(TOKEN_PARDINI)
        .then((res) => res)
        .catch((error) => {
          throw error;
        });

      newConfig.headers.Authorization = response.data.TokenResult.token;
    } catch {
      toast(
        'Ocorreu um erro e a busca não pode ser completada. Tente novamente.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  }
  return newConfig;
});

PardiniHomologApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token_homol');
    }
    return Promise.reject(error);
  }
);

export default PardiniHomologApi;
