import {
  SizeDimensionsVh,
  getMostAccessed,
  setMostAccessed,
  addDistanceToArray,
  downloadPdf,
  hasToken,
  decryptToken,
  identifyDomains,
  SetPageView,
} from './functions';

export {
  SizeDimensionsVh,
  getMostAccessed,
  setMostAccessed,
  addDistanceToArray,
  downloadPdf,
  hasToken,
  decryptToken,
  identifyDomains,
  SetPageView,
};
