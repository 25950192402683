import { constants } from 'utils/constants';
import { CmsApi, PardiniApi, PardiniHomologApi } from 'utils/constants/api';
import { toast } from 'react-toastify';

const defineEndpoint = (endpoint, id, query) => {
  if (typeof endpoint === 'function') {
    return endpoint(id);
  }
  if (query) {
    return `${endpoint}?${query}`;
  }
  if (id) {
    return `${endpoint}/${id}`;
  }
  return endpoint;
};

const defineAPI = api => {
  if (api === constants.PARDINI_API) {
    return PardiniApi;
  }
  if (api === constants.PARDINI_HOMOLOG) {
    return PardiniHomologApi;
  }
  return CmsApi;
};

export const requestAPI = ({ font, verb, endpoint, data, id, query }) => {
  const API = defineAPI(font);
  switch (verb) {
    case 'GET':
      return API.get(defineEndpoint(endpoint, id, query), data)
        .then(res => res)
        .catch(error => {
          throw error;
        });
    case 'POST':
      return API.post(defineEndpoint(endpoint, id, query), data)
        .then(res => res)
        .catch(error => {
          throw error;
        });
    default:
      return toast(
        'Não é possível realizar essa consulta. Por favor tente mais tarde.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
  }
};
