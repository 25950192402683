import { all } from 'redux-saga/effects';
import * as AppSaga from './AppSaga';
import * as TemplateSaga from './TemplateSaga';
import * as Services from './ServiceSaga';
import * as Unit from './UnitSaga';

function* Sagas() {
  yield all([
    AppSaga.watcherSaga(),
    Services.watcherSaga(),
    Unit.watcherSaga(),
    TemplateSaga.watcherSaga(),
  ]);
}

export default Sagas;
