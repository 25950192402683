import { takeLatest, put, select, call } from 'redux-saga/effects';
import * as Types from 'utils/constants/actions/actionTypes';
import { requestAPI } from 'utils/helpers/RequestHelper';
import * as endpoint from 'utils/constants/endpoints';
import { toast } from 'react-toastify';
import {
  SITE_PARDINI,
  CMS_API,
  EXAM_IMG_BLOG,
  EXAM_LAB_BLOG,
  VACCINE_BLOG,
} from 'utils/constants/constants';

export const getHostname = (state) => state.AppReducer.hostname;
export const getLocationState = (state) => state.AppReducer.locationState;

function* defSiteData(data, quantResults, currentEndpoint) {
  try {
    const hostname = yield select(getHostname);
    const locationState = yield select(getLocationState);
    let result = [];
    if (hostname === SITE_PARDINI) {
      result = data.filter(
        (item) =>
          item.site_name === hostname &&
          (item.state === locationState || item.state === 'all')
      );
    } else {
      result = data.filter((item) => item.site_name === hostname);
    }

    if (result.length > 0 && quantResults === 1) {
      return result[0];
    }

    if (result.length > 0) {
      return result;
    }

    if (currentEndpoint === endpoint.VACCINES && result.length <= 0) {
      return {};
    }

    if (currentEndpoint === endpoint.SEO && result.length <= 0) {
      return [];
    }

    return [];
  } catch {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload:
        'Estamos temporariamente em manutenção. Por favor tente mais tarde.',
    });
    return [];
  }
}

function* getStructure() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.STRUCTURE,
    });
    const result = yield call(defSiteData, resource.data, 1);
    yield put({
      type: Types.SET_STRUCTURE,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao identificar o domínio',
    });
  }
}

function* getBanner() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.BANNER,
    });
    const result = yield call(defSiteData, resource.data);
    yield put({
      type: Types.SET_BANNER,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar os banners da página',
    });
  }
}

function* getScheduleCards() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.SCHEDULE,
    });
    const result = yield call(defSiteData, resource.data);
    yield put({
      type: Types.SET_SCHEDULE_CARDS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar os cards de agendamento',
    });
  }
}

function* getQuickAccessCards() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.QUICK_ACCESS,
    });
    const result = yield call(defSiteData, resource.data);
    yield put({
      type: Types.SET_QUICK_ACCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar os cards de acesso rápido',
    });
  }
}

function* getServicesCards() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.SERVICES,
    });
    const result = yield call(defSiteData, resource.data);
    yield put({
      type: Types.SET_SERVICE_CARDS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar cards de serviços',
    });
  }
}

function* getFooter() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.FOOTER,
    });
    const result = yield call(defSiteData, resource.data, 1);
    yield put({
      type: Types.SET_FOOTER,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar o rodapé da página',
    });
  }
}

function* getUnitDetails() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.UNIT_DETAILS,
    });
    const result = yield call(defSiteData, resource.data, 1);
    yield put({
      type: Types.SET_UNIT_DETAILS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar os detalhes da página de unidades',
    });
  }
}

function* getInstitutional() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.INSTITUTIONAL,
    });
    const result = yield call(defSiteData, resource.data);
    yield put({
      type: Types.SET_INSTITUTIONAL,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar os dados institucionais',
    });
  }
}

function* getVaccineDetails() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.VACCINES,
    });
    const result = yield call(defSiteData, resource.data, 1, endpoint.VACCINES);
    yield put({
      type: Types.SET_VACCINE_DETAILS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro ao carregar os detalhes da página de vacinas',
    });
  }
}

function* getBlog(action) {
  try {
    const hostname = yield select(getHostname);
    const locationState = yield select(getLocationState);
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: `${endpoint.BLOG}/${hostname}?route=posts&category=${
        action.payload
      }${
        hostname === SITE_PARDINI ? `&tag=${locationState.toLowerCase()}` : ''
      }`,
    });

    const result = resource.data.slice(0, 3);
    switch (action.payload) {
      case VACCINE_BLOG:
        yield put({
          type: Types.SET_VACCINE_BLOG,
          payload: result,
        });
        break;
      case EXAM_IMG_BLOG:
        yield put({
          type: Types.SET_EXAM_IMG_BLOG,
          payload: result,
        });
        break;
      case EXAM_LAB_BLOG:
        yield put({
          type: Types.SET_EXAM_LAB_BLOG,
          payload: result,
        });
        break;
      default:
        break;
    }
  } catch {
    toast(
      'Não foi possível buscar atualizações do blog. Tente novamente mais tarde!',
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }
}

function* getSeoTags() {
  try {
    const resource = yield requestAPI({
      font: CMS_API,
      verb: 'GET',
      endpoint: endpoint.SEO,
    });
    const result = yield call(defSiteData, resource.data);
    yield put({
      type: Types.SET_SEO_TAGS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: Types.SET_GENERAL_ERROR,
      payload: 'Erro na obtenção de meta tags',
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.ASYNC_GET_STRUCTURE, getStructure);
  yield takeLatest(Types.ASYNC_GET_BANNER, getBanner);
  yield takeLatest(Types.ASYNC_GET_SCHEDULE_CARDS, getScheduleCards);
  yield takeLatest(Types.ASYNC_GET_QUICK_ACCESS_CARDS, getQuickAccessCards);
  yield takeLatest(Types.ASYNC_GET_SERVICE_CARDS, getServicesCards);
  yield takeLatest(Types.ASYNC_GET_FOOTER, getFooter);
  yield takeLatest(Types.ASYNC_GET_UNIT_DETAILS, getUnitDetails);
  yield takeLatest(Types.ASYNC_GET_INSTITUTIONAL, getInstitutional);
  yield takeLatest(Types.ASYNC_GET_VACCINE_DETAILS, getVaccineDetails);
  yield takeLatest(Types.ASYNC_GET_EXAM_LAB_BLOG, getBlog);
  yield takeLatest(Types.ASYNC_GET_EXAM_IMG_BLOG, getBlog);
  yield takeLatest(Types.ASYNC_GET_VACCINE_BLOG, getBlog);
  yield takeLatest(Types.ASYNC_GET_SEO_TAGS, getSeoTags);
}
