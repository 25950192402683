import { BlipChat } from 'blip-chat-widget';
import { identifyDomains } from 'utils/functions';

const getChatValues = (domain, location) => {
  const chatConfig = [
    {
      brand: 'hermespardini',
      state: 'SP',
      key: 'aHBzcGJjOmI3OGJhZDU4LWM1NTktNGNhMi04ZGYxLTA4ZjM4MWMxN2EwOQ==',
      color: '#B91F2E',
      icon: '',
      withCustomCommonUrl: 'https://grupopardini.chat.blip.ai/',
    },
    {
      brand: 'hermespardini',
      state: 'MG',
      key: 'aHBiaGJjOjQyYzVhMWU2LTM5MTEtNGEyZi1hMzdjLThjYmEwNTBjOWJkZA==',
      color: '#B91F2E',
      withCustomCommonUrl: 'https://grupopardini.chat.blip.ai/',
    },
    {
      brand: 'centrodemed',
      state: '',
      key: 'aHBjbW5nYmM6MzYzZGJlYmMtMjhjNS00YjI3LWE2MTAtMmUzZDFmOTQ1Mzc5',
      color: '#166938',
      withCustomCommonUrl: 'https://grupopardini.chat.blip.ai/',
    },
    {
      brand: 'ecoar',
      state: '',
      key: 'aHBlY29hcmJjOmJkMzc5MTlkLWUyODItNGRjNi04OWE4LTBiZWYyMDBiMGQzNg==',
      color: '#032969',
      withCustomCommonUrl: 'https://grupopardini.chat.blip.ai/',
    },
    {
      brand: 'padrao',
      sate: '',
      key: 'aHBwYWRyYW9iYzplZGViNjMyNS03NWI2LTQ4ZjItODMwNS0zZGU4ZDM1NjI3N2Y=',
      color: '#e20613',
      withCustomCommonUrl: 'https://grupopardini.chat.blip.ai/',
    },
  ];

  return chatConfig.find((item) => {
    return (
      item.brand === domain &&
      item.state === (domain === 'hermespardini' ? location : '')
    );
  });
};

export const defChatHelper = (location) => {
  const domain = identifyDomains();
  const element = document.getElementById('blip-chat-container');
  if (element) {
    document.body.removeChild(element);
  }
  const locationState = domain === 'hermespardini' ? location : '';
  const values = getChatValues(domain, locationState);

  if (values) {
    new BlipChat()
      .withAppKey(values.key)
      .withButton({ color: values.color, icon: '' })
      .withCustomCommonUrl(values.withCustomCommonUrl)
      .build();
  }
};
