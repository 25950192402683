import axios from 'axios';
import CryptoJS from 'crypto-js';
import { BASE_URL_PARDINI_HOMOLOG } from 'utils/constants/routes';
import { CRYPTO_TOKEN_KEY } from 'utils/constants/constants';

const TokenHomologApi = axios.create({
  baseURL: BASE_URL_PARDINI_HOMOLOG,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Authorization',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  },
});

TokenHomologApi.interceptors.response.use(
  (response) => {
    const token = JSON.stringify(response.data.TokenResult);
    const encryptToken = CryptoJS.AES.encrypt(token, CRYPTO_TOKEN_KEY);
    localStorage.setItem('token_homol', encryptToken);
    return response;
  },
  (error) => {
    localStorage.removeItem('token_homol');
    return Promise.reject(error);
  }
);

export default TokenHomologApi;
