import axios from 'axios';
import ReactGA from 'react-ga';
import * as moment from 'moment';
import CryptoJS from 'crypto-js';
import { useState, useEffect } from 'react';
import {
  QUANT_ITEMS_CARD,
  CRYPTO_TOKEN_KEY,
  SITE_CMNG,
  SITE_PARDINI,
  SITE_PADRAO,
  SITE_HUMBERTO_ABRAO,
  SITE_ECOAR,
} from 'utils/constants/constants';

export const SizeDimensionsVh = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimensions;
};

export const SetPageView = (hostname) => {
  if (hostname === 'hermespardini' && process.env.NODE_ENV === 'production') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const getMostAccessed = (key, disregardItems) => {
  if (localStorage.getItem(key)) {
    const result = JSON.parse(localStorage.getItem(key));
    result.sort((prev, next) => (next.access < prev.access ? -1 : 1));
    return result
      .filter((mostAccessItem) =>
        disregardItems.every(
          (disregardItem) => disregardItem.id !== mostAccessItem.id
        )
      )
      .slice(0, QUANT_ITEMS_CARD - disregardItems.length);
  }
  return [];
};

export const setMostAccessed = (key, value) => {
  if (key && value.id) {
    const aux = value;
    aux.access = 1;

    if (localStorage.getItem(key)) {
      const result = JSON.parse(localStorage.getItem(key));
      const indexFound = result.findIndex((item) => item.id === value.id);

      if (indexFound >= 0) {
        result[indexFound].access += 1;
      } else {
        result.push(aux);
      }
      localStorage.setItem(key, JSON.stringify(result));
    } else {
      localStorage.setItem(key, JSON.stringify([aux]));
    }
  }
};

/**
 * Essa função aplica a fórmula de Harvecine, ela faz o cálculo
 * da distância entre dois pontos de uma esfera a partir de
 * suas latitudes e longitudes. Na situação atual a fórmula é
 * usada para calcular a distancia entre a posição inicial do
 * cliente, usando a Geolocalização, e o laboratório mais próximo.
 */
export const distanceCalc = (p1, p2) => {
  const rad = (x) => x * (Math.PI / 180);
  const earthRadius = 6378137;
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const faceA =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) ** 2;
  const faceC = 2 * Math.atan2(Math.sqrt(faceA), Math.sqrt(1 - faceA));
  const distance = ((earthRadius * faceC) / 1000).toFixed(2);
  return distance;
};

export const addDistanceToArray = (companies, coordsDefault) => {
  companies.forEach((company) => {
    const distance = distanceCalc(coordsDefault, {
      lat: company.latitude,
      lng: company.longitude,
    });
    Object.assign(company, { distancia: distance });
  });
  companies.sort((a, b) => a.distancia - b.distancia);
};

export const downloadPdf = (baseUrl, fileName) => {
  axios({
    url: baseUrl,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const decryptToken = (key) => {
  const encryptToken = localStorage.getItem(key);
  if (encryptToken) {
    const decryptedToken = CryptoJS.AES.decrypt(encryptToken, CRYPTO_TOKEN_KEY);
    const token = JSON.parse(decryptedToken.toString(CryptoJS.enc.Utf8));
    return token;
  }
  return null;
};

export const hasToken = (key) => {
  try {
    const token = decryptToken(key);
    if (token) {
      const now = moment();
      const endTime = moment(token.dataExpiracao);
      const duration = moment.duration(endTime.diff(now));
      return duration.asMinutes() > 0;
    }
    return false;
  } catch {
    localStorage.removeItem(key);
    return false;
  }
};

export const identifyDomains = () => {
  const domains = [
    SITE_PARDINI,
    SITE_ECOAR,
    SITE_PADRAO,
    SITE_HUMBERTO_ABRAO,
    SITE_CMNG,
  ];
  const finalResult = domains.find((domain) =>
    window.location.hostname.match(domain)
  );
  return finalResult;
};
