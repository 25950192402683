export const BANNER = 'banners';
export const BLOG = 'blogs';
export const FOOTER = 'footers';
export const POSTS = 'posts';
export const QUICK_ACCESS = 'quickaccess';
export const SERVICES = 'services';
export const STRUCTURE = 'structures';
export const SCHEDULE = 'schedules';
export const VACCINES = 'vaccines';
export const UNIT_DETAILS = 'units';
export const INSTITUTIONAL = 'institutionals';
export const SEO = 'seo';

/* Pardini endpoints */
export const TOKEN_PARDINI = 'authenticate';
export const AUTH_TOKEN = 'auth-token';
export const CHANGE_PASSWORD = 'change-pass';
export const UNITS = 'units';
export const GENERIC_SEARCH = 'generic-search';
export const SERVICES_LIST = 'exams';
export const SERVICE_DETAILS = 'exams/details';
export const UNIT_SERVICES = 'units/exams';
export const FORM_LOGIN = 'https://www.hermespardini.com.br/';
export const RECAPTCHA_VERIFY = 'recaptcha';
