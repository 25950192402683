export const PAGE_LAB = 'LAB';
export const PAGE_IMG = 'IMG';
export const PAGE_VAC = 'VAC';
export const PAGE_UNIT = 'UNIT';
export const PAGE_HOME = 'HOME';
export const PAGE_SERVICE = 'SRV';

export const CARD_LAB = 'LAB';
export const CARD_IMG = 'IMG';
export const CARD_VAC = 'VAC';
export const CARD_UNIT = 'UNIT';
export const CARD_MEGR = 'MEGR';

export const VACCINE_BLOG = 'vacinas';
export const EXAM_LAB_BLOG = 'exames-laboratoriais';
export const EXAM_IMG_BLOG = 'exames-por-imagem';

export const GOOGLE_MAPS = (domain) => {
  const mapsKey = {
    ecoar: 'AIzaSyCbXHzJRPej1SsB2YAr-6nRGp7yRhEFjmQ',
    centrodemed: 'AIzaSyCbXHzJRPej1SsB2YAr-6nRGp7yRhEFjmQ',
    hermespardini: 'AIzaSyCbXHzJRPej1SsB2YAr-6nRGp7yRhEFjmQ',
    default: '',
  };
  return mapsKey[domain] || mapsKey.default;
};
export const GOOGLE_RECAPTCHA_KEY = `${process.env.REACT_APP_RECAPTCHA_KEY}`;
export const GOOGLE_RECAPTCHA_SECRET_KEY = `${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}`;
export const HP_ANALYTICS = 'UA-24838302-2';

export const MOST_ACCESS_UNIT = 'MOST_ACCESS_UNIT';
export const MOST_ACCESS_EXAM_LAB = 'MOST_ACCESS_EXAM_LAB';
export const MOST_ACCESS_EXAM_IMG = 'MOST_ACCESS_EXAM_IMG';
export const MOST_ACCESS_VACCINE = 'MOST_ACCESS_VACCINE';
export const LOCATION_STATE = 'LOCATION_STATE';

export const QUANT_ITEMS_CARD = 4;

export const SITE_PARDINI = 'hermespardini';
export const SITE_ECOAR = 'ecoar';
export const SITE_CMNG = 'centrodemed';
export const SITE_PADRAO = 'padrao';
export const SITE_HUMBERTO_ABRAO = 'humbertoabrao';

export const PARDINI_API = 'PARDINI_API';
export const CMS_API = 'CMS_API';
export const PARDINI_HOMOLOG = 'PARDINI_HOMOLOG';

export const CRYPTO_TOKEN_KEY = `${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}`;

export const SIZE_MENU_MOBILE = 1080;

export const APP_STORE = (domain) => {
  const link = {
    ecoar: {
      google: 'https://play.google.com/store/apps/details?id=br.com.ecoar.app',
      apple: 'https://apps.apple.com/br/app/ecoar/id1499476937',
    },
    hermespardini: {
      google:
        'https://play.google.com/store/apps/details?id=br.com.hermespardini.app_hp',
      apple: 'https://apps.apple.com/br/app/hermes-pardini-novo/id1516047829',
      alexa:
        'https://www.amazon.com.br/Hermes-Pardini/dp/B08D6ZMGN2/ref=sr_1_1?brr=1&dchild=1&qid=1595267763&rd=1&s=alexa-skills&sr=1-1',
    },
    centrodemed: {
      apple: 'https://apps.apple.com/br/app/cmng/id1441951628',
      google: 'https://play.google.com/store/apps/details?id=br.com.cmng.app',
    },
  };
  return link[domain];
};
