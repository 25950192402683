import {
  SET_DEFAULT_LOCATION, SET_SITE_HOSTNAME, SET_LOCATION_STATE, ASYNC_SET_GENERIC_SEARCH
} from './actionTypes';

export const setDefaultLocation = (newValue) => ({
  type: SET_DEFAULT_LOCATION,
  payload: newValue
});

export const setSiteHostname = (hostname) => ({
  type: SET_SITE_HOSTNAME,
  payload: hostname
});

export const setLocationState = (locationState) => ({
  type: SET_LOCATION_STATE,
  payload: locationState
});

export const setGenericSearch = (genericSearch) => ({
  type: ASYNC_SET_GENERIC_SEARCH,
  payload: genericSearch
});
