import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import Units from '../ducks/Units';
import Services from '../ducks/Services';
import TemplateReducer from './TemplateReducer';

export const reducers = combineReducers({
  AppReducer,
  Units,
  Services,
  TemplateReducer,
});
