export const HOME = '/';
export const UNIT = '/lojas';
export const UNIT_DETAILS = '/lojas/:id';
export const UNIT_DETAILS_FEDERATION = '/lojas/:id/:federation';
export const EXAM_LAB = '/exames-laboratoriais';
export const EXAM_IMG = '/exames-por-imagem';
export const EXAM_MET_GRAF = '/exames-met-graf';
export const VACCINE = '/vacinas';
export const VACCINE_TRAIL = '/vacinas/trilha';
export const VACCINE_TRAIL_DETAILS = '/vacinas/trilha/:trail';
export const SERVICE = '/servicos';
export const SERVICE_ANCHOR = '/servicos/:id/:uf?';
export const INSTITUTIONAL = '/institucional';
export const INSTITUTIONAL_ANCHOR = '/institucional/:id/:uf?';
export const CONTACT_US = '/fale-conosco';
export const SOCIAL_EVENTS_PROTOCOL = '/protocolo-eventos-sociais';
export const AMIL_CLIENTS = '/formulario-amil';
export const ESTIMATE = '/orcamento';
export const HOW_TO_ACCESS = '/como-acessar-meus-exames';
export const PERMANENT_PASSWORD = '/senha-definitiva';

export const BASE_URL_PARDINI = `${process.env.REACT_APP_API_URL}`;
export const RESULTS_URL_PARDINI = `${process.env.REACT_APP_RESULTS_CARD_REDIRECT_URL}`;
export const BASE_URL_PARDINI_HOMOLOG = `${process.env.REACT_APP_API_URL_HOMOL}`;
export const BASE_URL_CMS = `${process.env.REACT_APP_API_URL_CMS}`;

export const BASE_URL_GOOGLE = 'https://www.google.com';

export const CONSULTA_NF = 'https://www.hermespardini.com.br/hpnfe/';
